<template>
    <footer id="footer">
        © {{ CurrentYear }} Great Commission Movement of Ghana. All rights reserved.
    </footer>
</template>
<script>
export default {
    data() {
        return {
            CurrentYear: new Date().getFullYear(),
        }
    },
}
</script>